<template>
  <div class="mobile_activity_container">
    <div class="title">
      <div class="main-title">新闻<font>/NEWS</font>
      </div>
      <div class="sub-title">企业动态 及时报道</div>
    </div>
    <div class="list">
      <div class="item" @click="lookInfo(item.id)" v-for="item in newsList" :key="item.id">
        <img class="cover" :src="item.cover" />
        <div class="title">{{item.title}}</div>
        <div class="time">{{dateFormat(item.issue_time, "y / m / d")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [],
    };
  },
  created() {
    this.$api("/official/page/mobile_news_list").then(({ data }) => {
      this.newsList = data;
    });
  },
  methods: {
    lookInfo(id) {
      this.$router.push({ path: "/mobile/news_info", query: { id: id } });
    },
    changeTabIdx(idx) {
      this.tabIdx = idx;

      this.showActivityList = false;
      setTimeout(() => {
        this.showActivityList = true;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
.block_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile_activity_container {
  margin: 0 50px;
  margin-bottom: 300px;

  > .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 77px;
      font-size: 73px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 100px;
      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 19px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 76px;
    }
  }

  .list {
    .item {
      margin-top: 58px;

      .cover {
        width: 1344px;
        height: 806px;
        border-radius: 54px;
      }
      .title {
        font-size: 65px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 92px;
      }
      .time {
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 69px;
      }
    }
  }
}
</style>